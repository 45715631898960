<template>
  <el-card class="dequEdit">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'">
        <!-- <childTab2 /> -->
        <childTab3 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="file" :key="'file'">
        <!-- <childTab2 /> -->
        <childTab2 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>

<script>
import childTab1 from './TabChild/DequEditMain.vue';
import childTab2 from './TabChild/DequEditAbout.vue';
import childTab3 from './TabChild/DequEditAppr.vue';
export default {
  name: 'dequEdit',
  components: {
    childTab1,
    childTab2,
    childTab3
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style scoped></style>
