<template>
  <div class="vd_button_group ccc">
    <span v-if="isShow === true">
      <el-button type="success" @click="openEdit" size="small" :disabled="!btn.edit">编辑</el-button>
    </span>
    <span v-if="isShow === false">
      <el-button type="primary" @click="submit(formData)" size="small">保存</el-button>
      <el-button @click="closeEdit" size="small">取消</el-button>
    </span>
    <span class="vd_tag_position">
      <!-- <el-form-item :prop="propInfo.no" :label="propInfo.name"> -->
      <div style="margin-right: 50px">
        <div class="disFlex">
          <div class="text vd_mr_12">{{ propInfo.name }}</div>
          <el-input v-model="formData[propInfo.no]" clearable size="small" maxlength="10" show-word-limit disabled></el-input>
        </div>
      </div>
      <!-- </el-form-item>   -->
      <div class="disFlex vg_ml_8">
        <span class="vg_tag_label">状态：</span>
        <el-tag :type="helper.getStatusName(formData.status).type">{{ helper.getStatusName(formData.status).name }} </el-tag>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: 'editHeader',
  props: {
    isShow: Boolean,
    btn: Object,
    formData: Object,
    propInfo: Object
  },
  methods: {
    openEdit() {
      this.$emit('openEdit');
    },
    closeEdit() {
      this.$emit('closeEdit');
    },
    submit(formData) {
      this.$emit('submit');
    }
  }
};
</script>

<style scoped lang="scss">
.disFlex {
  display: flex;
  align-items: center;
}

.vd_tag_position {
  margin-right: 32px;
  display: flex;
}
.text {
  width: 150px;
  text-align: right;
}

.ccc {
  display: flex;
  justify-content: space-between;
}
.vd_mr_12 {
  margin-right: 12px;
}
</style>
