<template>
  <div class="dequEditMain">
    <div class="dequEdit_header">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :formData="formData"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        :propInfo="{ name: '委托设计单号:', no: 'dequ_no', placeholder: '根据员工打样编号生成', disabled: true }"
        @submit="submit('cpttForm')"
      />
    </div>
    <el-form :model="formData" :inline="true" :rules="formDataRule" ref="formDataRef" label-width="120px" :disabled="isShow">
      <div class="dequEdit_content">
        <dequForm :formData="formData" @updateFormData="updateFormData" :noFlag="true" />
      </div>
      <div>
        <span>按钮仅供设计师上传图稿，客户附件请保存后至相关文档上传!</span>
        <div class="flexV vg_mt_8 vg_mb_8">
          <el-upload
            ref="upload"
            :action="getUploadUrl"
            :before-upload="beforeUpload"
            :data="{ docu_id: 0, dequ_id: formData.dequ_id, docu_names: deleteUploadFiles }"
            :on-success="uploadSuccess"
            :show-file-list="true"
            class="flexHV vg_mr_8"
            multiple
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <el-button :disabled="false" size="small" type="success" @click="downClick">批量下载</el-button>
          <span class="vg_ml_8">文件大小不得超过 300MB!</span>
        </div>
        <div>
          <div>
            <el-form ref="formDataRef" :disabled="false" :inline="true" :model="formData" :rules="formDataRule" label-width="120px">
              <el-table
                :data="formData.fileData"
                :row-class-name="tableRowClassName"
                border
                @header-click="headerClick"
                @selection-change="handleSelectionChange"
              >
                <el-table-column align="center" type="selection" width="55"></el-table-column>
                <el-table-column align="center" type="index"></el-table-column>
                <el-table-column align="center" label="上传时间" prop="create_time">
                  <template v-slot="scope">
                    {{ helper.toTimeDay(scope.row.create_time) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="上传人" prop="dequ_designer_name"></el-table-column>
                <el-table-column align="center" label="文件名称" prop="docu_name"></el-table-column>
                <el-table-column align="center" label="格式" prop="docu_suffix"></el-table-column>
                <el-table-column align="center" label="稿件等级" prop="dequ_pter_rank">
                  <template v-slot:header="scope"><span class="vg_deep_red">稿件等级</span></template>
                  <template v-slot="scope">
                    <el-form-item :prop="'fileData[' + scope.$index + '].dequ_pter_rank'" :rules="[{ required: true }]">
                      <div style="display: flex">
                        <el-select
                          v-model="formData.fileData[scope.$index].dequ_pter_rank"
                          :disabled="isShow || dequ_pter_rank_disabled"
                          filterable
                          size="small"
                        >
                          <el-option v-for="item in levelList" :key="item.id" :label="item.param1" :value="item.param1 + ''"></el-option>
                        </el-select>
                        <div style="display: flex; flex-direction: column; justify-content: center">
                          <el-tooltip content="复制" effect="dark" placement="top">
                            <el-link
                              :disabled="isShow || dequ_pter_rank_disabled"
                              :underline="false"
                              class="vg_cursor"
                              icon="el-icon-document-copy"
                              @click="copyCell(`dequ_pter_rank[copyDataFlag]${scope.row.dequ_pter_rank}[copyDataFlag]${scope.$index}`)"
                            ></el-link>
                          </el-tooltip>
                          <span style="height: 9px"></span>
                          <el-tooltip content="粘贴" effect="dark" placement="right">
                            <el-link
                              :disabled="isShow || dequ_pter_rank_disabled"
                              :underline="false"
                              class="vg_cursor"
                              icon="el-icon-finished"
                              @click="pasteCell('dequ_pter_rank', scope.row, scope.$index)"
                            ></el-link>
                          </el-tooltip>
                        </div>
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                  <template v-slot="scope">
                    <div class="flexHV">
                      <el-link :disabled="false" class="vg_pointer" type="success" @click="clickDownload(scope.row)">下载</el-link>
                      <el-link :disabled="isShow" class="vg_ml_8 vg_pointer" type="danger" @click="clickDelete(scope.row, scope.$index)">删除</el-link>
                      <el-link v-if="imgarr.indexOf(scope.row.docu_suffix) !== -1" :disabled="false" class="vg_ml_8 vg_pointer" @click="clickView(scope.row)"
                        >查看
                      </el-link>
                      <el-link v-if="'pdf' === scope.row.docu_suffix" :disabled="false" class="vg_ml_8 vg_pointer" @click="openPDF(scope.row.docu_url)"
                        >查看
                      </el-link>
                      <el-upload
                        :action="getUploadUrl"
                        :data="{ ...scope.row, docu_names: [] }"
                        :before-upload="beforeUpload"
                        :on-success="res => replacUploadSuccess(res, scope.row, scope.$index)"
                        :show-file-list="false"
                        class="flexHV vg_ml_8"
                        multiple
                      >
                        <el-link class="vg_pointer" type="primary">替换</el-link>
                      </el-upload>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </div>
        </div>
      </div>
    </el-form>
    <el-row class="vg_mt_16">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
    <el-dialog title="图片查看" :visible.sync="dialogVisible" width="50%">
      <el-row class="vd_dis">
        <el-image class="showImg" :src="showImgUrl" fit="contain"></el-image>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import editHeader from '@/views/component/editHeaderInput';
import inputUser from '@/views/component/inputUser';
import dequForm from './DequForm.vue';
import { dequAPI } from '@/api/modules/dequ';
import { get, post } from '../../../../api/request';
import { optnAPI } from '@/api/modules/optn';
import JSZip from 'jszip';
import helper from '@assets/js/helper';
import UrlEncode from '@assets/js/UrlEncode';
import { debounce } from 'lodash';

export default {
  name: 'dequEditMain',
  components: {
    editHeader,
    inputUser,
    dequForm
  },
  data() {
    return {
      btn: {},
      isShow: true,
      levelList: [],
      typeList: [],
      dialogVisible: false,
      showImgUrl: '',
      formData: {
        dequ_id: null, //
        dequ_no: null, //委托设计单号编号
        dequ_stff_id: null, //委托人
        cust_id: 0, //客户id
        dequ_stime: null, //设计开始时间
        dequ_petime: null, //要求交稿时间
        dequ_num: null, //要求设计产品数量
        dequ_sample: null, //是否提供原样
        dequ_remark: null, //设计内容
        dequ_brief: null, //简短的设计内容, 从设计内容截取15字
        fileData: [],
        status: null
      },
      stffForm: {
        stff_name: '',
        cptt_name: '',
        dept_name: '',
        dept_team_name: '',
        stff_id: Number,
        cptt_id: Number,
        dept_id: Number,
        dept_team_id: Number,
        stff_this_break: null,
        stff_last_break: null,
        stff_off_hour: null
      },
      fileDataCopy: [],
      formDataRule: {
        dequ_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        cust_id: [{ required: true, message: ' ', trigger: 'blur' }],
        dequ_stime: [{ required: true, message: ' ', trigger: 'blur' }],
        dequ_sample: [{ required: true, message: ' ', trigger: 'blur' }],
        dequ_petime: [{ required: this.$cookies.get('userInfo').dept_id === 16, message: ' ', trigger: 'blur' }],
        dequ_pter_rank: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      imgarr: [
        'bmp',
        'jpg',
        'png',
        'tif',
        'gif',
        'pcx',
        'tga',
        'exif',
        'fpx',
        'svg',
        'psd',
        'cdr',
        'pcd',
        'dxf',
        'ufo',
        'eps',
        'ai',
        'raw',
        'WMF',
        'webp',
        'avif',
        'apng',
        'jpeg'
      ],
      multiSelection: [],
      uploadFlag: true,
      beforeFlag: 0,
      successFlag: 0,
      uploadingFiles: [], // 存储正在上传的文件信息
      deleteUploadFiles: []
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    pasteCell(column, row, index) {
      navigator.clipboard
        .readText()
        .then(text => {
          let t = text.split('[copyDataFlag]');
          if (t.length > 1 && t[0] === column) {
            if (Number(t[2]) === index) return;
            let rangeIndex;
            if (Number(t[2]) > index) rangeIndex = [index, Number(t[2])];
            else rangeIndex = [Number(t[2]), index];
            this.formData.fileData.forEach((item, tIndex) => {
              if (tIndex >= rangeIndex[0] && tIndex <= rangeIndex[1]) {
                this.$set(item, column, t[1]);
              }
            });
          }
        })
        .catch(err => {
          console.error('无法读取剪贴板: ', err);
        });
    },
    copyCell(text) {
      navigator.clipboard.writeText(text);
    },
    headerClick(column, event) {
      console.log(column, event);
    },
    initData() {
      this.getDequInfo();
      this.getLevel();
    },
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    async openPDF(url) {
      // const response = await fetch(helper.megPath(url), { method: 'GET' });
      // const blob = await response.blob();
      // let objectURL = URL.createObjectURL(blob);
      // window.open(objectURL);
      // URL.revokeObjectURL(objectURL);
      window.open(helper.megPath(url));
    },
    // 批量下载
    downClick() {
      if (this.multiSelection.length === 0) return this.$message.warning('至少选择一条数据');
      const zip = new JSZip();
      const cache = {};
      const promises = [];
      let companyAttachmentsList = [];
      for (let i = 0; i < this.multiSelection.length; i++) {
        let docuUrl = this.helper.megPath(this.multiSelection[i].docu_url);
        companyAttachmentsList.push(docuUrl);
      }
      companyAttachmentsList.forEach((item, index) => {
        let { docu_name, docu_suffix } = this.multiSelection[index];
        const promise = this.helper.downloadZip(item).then(data => {
          // 下载文件, 并存成ArrayBuffer对象
          const arr_name = item.split('/');
          const file_name = docu_name + '.' + docu_suffix; // 获取文件名
          zip.file(file_name, data, { binary: true }); // 逐个添加文件
          cache[file_name] = data;
        });
        promises.push(promise); //加到promises队列里
      });
      Promise.all(promises).then(() => {
        //异步队列全部完成时 执行下面代码
        zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流
          saveAs(content, '下载文件包.zip'); // 利用file-saver保存文件
        });
      });
    },
    getDequInfo() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      if (props.form_id) {
        get(dequAPI.getDequById, { dequ_id: props.form_id })
          .then(res => {
            if (res.data.code === 0) {
              this.btn = res.data.data.btn;
              this.formData.dequ_id = res.data.data.form.dequ_id;
              this.formData.dequ_no = res.data.data.form.dequ_no;
              this.formData.dequ_stff_id = res.data.data.form.dequ_stff_id;
              this.formData.cust_id = res.data.data.form.cust_id === 0 ? '' : res.data.data.form.cust_id;
              this.formData.dequ_stime = new Date(res.data.data.form.dequ_stime * 1000);
              this.formData.dequ_petime = res.data.data.form.dequ_petime === 0 ? '' : new Date(res.data.data.form.dequ_petime * 1000);
              this.formData.dequ_num = res.data.data.form.dequ_num;
              this.formData.dequ_sample = res.data.data.form.dequ_sample + '';
              this.formData.dequ_remark = res.data.data.form.dequ_remark;
              this.formData.fileData = res.data.data.form.dequ_pter_list;
              this.formData.status = res.data.data.form.status;
              this.fileDataCopy = JSON.parse(JSON.stringify(this.formData.fileData));
              this.stffForm.dept_name = res.data.data.form.dept_name;
              this.stffForm.dept_team_name = res.data.data.form.dept_team_name;
              this.stffForm.stff_name = res.data.data.form.stff_name;
              this.uploadingFiles = this.formData.fileData.map(x => x.docu_name + '.' + x.docu_suffix);
              this.deleteUploadFiles = [];
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            }
            // this.res = res.data.
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          });
      }
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    submit() {
      let length = this.formData.fileData.filter(item => !item.dequ_pter_rank).length;
      if (length > 0) {
        this.$message.warning('请把 子表-稿件等级 补充完整');
        return false;
      }
      this.$refs.formDataRef.validate(valid => {
        if (valid) {
          this.$confirm('确定修改?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.saveFun();
            })
            .catch(() => {});
        } else {
          console.log('error submit!');
          this.$message.warning('请把必填内容补充完整');
          return false;
        }
      });
    },
    saveFun() {
      const form = {};
      form.dequ_id = this.formData.dequ_id;
      form.dequ_stff_id = this.formData.dequ_stff_id;
      form.cust_id = this.formData.cust_id;
      form.dequ_stime = this.helper.toTimeVal(this.formData.dequ_stime);
      if (this.formData.dequ_petime) {
        form.dequ_petime = this.helper.toTimeVal(this.formData.dequ_petime);
      } else {
        form.dequ_petime = 0;
      }
      // form.dequ_petime = this.formData.dequ_petime.getTime() / 1000
      form.dequ_num = this.formData.dequ_num;
      form.dequ_sample = this.formData.dequ_sample;
      form.dequ_remark = this.formData.dequ_remark;
      if (form.dequ_remark) {
        form.dequ_brief = this.formData.dequ_remark.substring(0, 16);
      }
      let dequPterList = JSON.parse(JSON.stringify(this.formData.fileData));
      this.fileDataCopy = this.fileDataCopy.filter(item => dequPterList.every(item1 => item.dequ_pter_id !== item1.dequ_pter_id));
      for (let i in this.fileDataCopy) {
        this.fileDataCopy[i].destroy_flag = 1;
      }
      dequPterList = dequPterList.concat(this.fileDataCopy);
      form.dequ_pter_list = dequPterList;

      post(dequAPI.editDequ, form)
        .then(res => {
          if (res.data.code === 0) {
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.$message.success(res.data.msg);
            this.getDequInfo();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    closeEdit() {
      this.$confirm('确定取消编辑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.getDequInfo();
        })
        .catch(() => {});
    },
    updateFormData(val) {
      this.formData.dequ_no = val.dequ_no;
      this.formData.dequ_stff_id = val.dequ_stff_id;
      this.formData.cust_id = val.cust_id;
      this.formData.dequ_remark = val.dequ_remark;
      this.formData.dequ_num = val.dequ_num;
      this.formData.dequ_stime = val.dequ_stime;
      this.formData.dequ_petime = val.dequ_petime;
      this.formData.dequ_sample = val.dequ_sample;
    },
    beforeUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 300;
      if (!isLt5M) {
        this.$message.error('文件大小不得超过 300MB!');
      }
      if (this.formData.fileData.find(x => x.docu_name + x.docu_suffix === file.name)) {
        this.message();
        return false;
      }
      if (this.uploadingFiles.includes(file.name)) {
        this.message();
        return false;
      }
      this.uploadingFiles.push(file.name);
      this.beforeFlag++;
      return isLt5M;
    },
    message: debounce(function () {
      this.$message.error('相同文件不得重复上传！');
    }, 1000),
    uploadSuccess(res) {
      if (res.code === 0) {
        this.formData.fileData.push({
          ...res.data[0],
          dequ_pter_rank: this.$cookies.get('userInfo').dept_id === 12 ? '' : '其他',
          docu_type: '',
          dequ_designer_name: res.data[0].user_name,
          dequ_designer_stff_id: res.data[0].stff_id,
          dequ_pter_starts: 1,
          dequ_pter_type: 1
        });
      } else {
        this.$message.warning('该文件类型不支持');
      }
      this.successFlag++;
      if (this.successFlag === this.beforeFlag) {
        setTimeout(() => {
          this.$refs.upload.clearFiles(); //去掉文件列表
          this.successFlag = 0;
          this.beforeFlag = 0;
        }, 500);
      }
    },
    replacUploadSuccess(res, row, index) {
      if (res.code === 0) {
        row.docu_suffix = res.data[0].docu_suffix;
        row.docu_url = res.data[0].docu_url;
        row.docu_name = res.data[0].docu_name;
        this.uploadingFiles[index] = res.data[0].docu_name + '.' + res.data[0].docu_suffix;
        this.$message.success('替换成功!');
      } else {
        this.$message.warning('该文件类型不支持');
      }
    },
    getLevel() {
      get(optnAPI.getOptnByPermId, {
        perm_id: 10002
      })
        .then(res => {
          if (res.data.code === 0) {
            this.levelList = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
      get(optnAPI.getOptnByPermId, {
        perm_id: 10001
      })
        .then(res => {
          if (res.data.code === 0) {
            this.typeList = res.data.data.form.optn_cntt_list;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    clickDownload(row) {
      this.$confirm('确定下载?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.docuUrl = this.helper.megPath(row.docu_url);
          this.docuName = row.docu_name + '.' + row.docu_suffix;
          this.helper.downloadItemC(this.docuUrl, this.docuName);
        })
        .catch(() => {});
      // downloadItem
    },
    clickDelete(row, index) {
      this.$confirm('确定删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.formData.fileData.splice(index, 1);
          const docuName = row.docu_name + '.' + row.docu_suffix;
          let uIndex = this.uploadingFiles.indexOf(docuName);
          uIndex !== -1 ? this.uploadingFiles.splice(uIndex, 1) : '';
          this.deleteUploadFiles.push(row.docu_name);
        })
        .catch(() => {});
    },
    clickView(row) {
      this.dialogVisible = true;
      this.showImgUrl = this.helper.megPath(row.docu_url);
    },
    tableRowClassName(row, index) {
      if (row.row.destroy_flag === '1') {
        return 'hidden-row';
      } else {
        return '';
      }
    }
  },
  computed: {
    helper() {
      return helper;
    },
    getUploadUrl() {
      return this.helper.modePrepend(dequAPI.uploadDequDocuments);
    },
    dequ_pter_rank_disabled() {
      let { dept_name, stff_id } = this.$cookies.get('userInfo');
      if (stff_id === 1) return false;
      return !['电商部', '设计部'].includes(dept_name);
    }
  }
};
</script>

<style scoped lang="scss">
.el-icon-document-copy:hover {
  color: rgb(64, 158, 255);
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}

::v-deep .el-upload {
  width: auto;
  height: auto;
  border: none;
  border-radius: 0;
}
::v-deep .hidden-row {
  display: none;
}
.showImg {
  display: flex;
  align-items: center;
}
.vd_dis {
  display: flex;
  justify-content: center;
}
</style>
